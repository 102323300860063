@import '../../../styles/customMediaQueries.css';

/* Styles for custom sections: classes shared by <SectionColumns>, <SectionArticle>, <SectionFeatures>, <SectionCarousel>, <SectionHero> etc. */
.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  justify-content: start;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.blockContainer {
  padding: 64px 32px 0 32px;

  &:first-child {
    padding: 0 32px;
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  max-width: 30ch;
}

.description {
  composes: align;
  max-width: 65ch;
}

.ctaButton {
  composes: align;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

/**
 * Theme: dark
 * These styles are at the bottom of the file so that they overwrite rules for default "light" theme.
 */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6 {
  color: var(--colorWhite);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

.darkTheme p,
.darkTheme li,
.darkTheme blockquote {
  color: rgba(255, 255, 255, 0.85);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

/* link on dark theme */
.darkTheme a {
  color: white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight);
  }
}

/* button on dark theme */
.darkTheme .ctaButton {
  border: 1px solid var(--marketplaceColorDark);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.darkTheme hr {
  border-color: var(--colorGrey700);
}

/* dark inline code */
.darkTheme code {
  background-color: var(--colorGrey700);
  color: var(--colorGrey100);
}

/* dark code block */
.darkTheme pre {
  background-color: var(--colorGrey700);
}

/* Custom styling for some of the dashboard widgets */
[class*="Link_link__lXwyG"] {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[id^="why-hire-middle-schooler-athlete-pic-"] {
  background: rgba(98, 72, 255, 0.04);
  padding: 16px;
  gap: 16px;
  border-radius: 4px;
}

[id^="why-hire-middle-schooler-athlete-pic-"] [class^="BlockDefault_media__"] {
  height: 50px;
  width: 50px;
}

[id^="why-hire-middle-schooler-athlete-pic-"] [class^="Heading_h3__"] {
  color: #6248FF;
  font-size: 20px;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

[id^="featured-sports-landing-page"] {
  width: 260px !important;
}

[id^="featured-sports-landing-page"] h3 {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  text-align: left;
}

[id^="featured-sports-landing-page"] [class^="BlockDefault_media__"] {
  height: 186px;
  width: 252px;
}

[id^="featured-sports"] header {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

[id^="featured-sports"] p {
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

[id^="featured-sports"] {
  background-color: white;
}


[id^="how-to-hire-a-coach"] [class^="Ingress_ingress__"] {
  font-size: 18px;
}

[id^="how-to-hire-a-coach-img"] [class^="AspectRatioWrapper_root__"] {
  height: 50px;
  width: 50px;
}

[id^="how-to-hire-a-coach-img"] h3 {
  font-family: Aleo;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
}

[id^="how-to-hire-a-coach-img"] a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
  text-align: center;
}

[id^="faq-find-a-coach"] div {
  display: flex;
  align-items: center;
  justify-content: center;
}

[id^="faq-find-a-coach"] a {
  width: 272px;
  color: #4A4A4A;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[id^="why-join-kck"] {
  background-color: white;
}

[id^="why-join-kck-img"] [class^="BlockDefault_media__"] {
  height: 88px;
  width: 88px;
  background-color: #6248FF14;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[id^="why-join-kck-img"] [class^="AspectRatioWrapper_root__"] {
  height: 40px;
  width: 40px;
}

[id^="why-join-kck-img"] [class^="AspectRatioWrapper_aspectBox__"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

[id^="getting-started-img"] [class^="AspectRatioWrapper_root__"] {
  height: 50px;
  width: 50px;
}

[id^="getting-started-img"] [class^="BlockDefault_media__"] {
  height: 50px;
  width: 50px;
  align-self: flex-start;
  @media (--viewportMedium) {
    align-self: center;
  }
}

[id^="getting-started-img"] {
  flex-direction: column;
  gap: 8px;
  @media (--viewportMedium) {
    flex-direction: row;
    gap: 32px;
  }
}

[id^="getting-started-img"] [class^="Link_link__"] {
  background-color: #6248FF;
  align-self: center;
  /* display: none; */
}

[id^="getting-started-button"] {
  background-color: white;
}

[id^="getting-started-button"] [class^="SectionContainer_sectionContent__"] {
  padding: 0;
}

[id^="getting-started-button"] [class^="SectionBuilder_sectionDetails__"] {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* @media (--viewportMedium) {
    margin-left: 336px;
    margin-right: 336px;
  } */
}

[id^="getting-started-button"] a {
  background-color: #6248FF;
  width: 100%;
  margin-bottom: 20px !important;
  text-align: center;
  @media (--viewportMedium) {
    width: 272px;
  }
  margin-bottom: 100px;
}

[id^="getting-started-button"] a:hover {
  background-color: #5037CC; /* Change this to the desired hover color */
}

[id^="help-wanted"] [class^="Link_link__"] {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  height: 48px;
  width: 100%;
  @media (--viewportMedium) {
    width: 272px;
  }
}

[id^="help-wanted"] a {
  color: #4A4A4A;
}

[id^="help-wanted"] p {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-top: 24px !important;
  margin-bottom: 4px !important;
  @media (--viewportMedium) {
    margin-bottom: 24px !important;
    font-size: 28px;
  }
}

[id^="recommended-listings"] {
  background-color: white;
}

[id^="recommended-listings"] p {
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

/* If you wanna revert, Start commenting from here */
[id^="recommended-listings"] img {
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 18px;
  }
  object-fit: contain;
  background-color: rgb(240, 240, 240);
}

[id^="recommended-listings"] [class^="SectionBuilder_blockContainer__"] {
  gap: auto;
  @media (--viewportMedium) {
    gap: 0px;
  }
}

[id^="recommended-listings"] [class^="CoachCard_root__"] {
  padding-left: 0px;
  @media (--viewportMedium) {

    align-self: center;
    padding-left: 12.5%;
  }
}

[id^="recommended-listings"] [class^="AspectRatioWrapper_root__"] {
  width: 100%;
  height: 250px;
  @media (--viewportMedium) {
    width: 252px;
    height: 186px;
  }
}
/* till here */

[id^="recommended-listings"] [class^="CoachCard_authorInfo__"] {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  text-align: left;
}

[id^="recommended-listings-slider"] {
  overflow-y: hidden;
}

[id^="help-wanted"] h2 {
  font-size: 28px;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 40px;
  }
}

[id^="why-join-kck"] a {
  color: #4A4A4A;
}

[id^="why-join-kck-img"] h3 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.63px;
  text-align: left;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

[id^="getting-started"] [class^="SectionContainer_sectionContent__"] {
  padding-bottom: 16px;
}


[id^="getting-started"] [class^="SectionBuilder_blockContainer__"] {
  padding-top: 32px;
  gap: 16px;
  padding-left: 32px;
  padding-right: 32px;
  @media (--viewportMedium) {
    padding-left: 128px;
    padding-right: 128px;
  }
}

[id^="getting-started"] [class^="P_p__"] {
  padding-top: 0;
  margin-top: 0;
}

[id^="f-a-q"] [class^="SectionContainer_sectionContent__"] {
  padding-bottom: 16px;
  padding-top: 16px;
}

[id^="f-a-q"] [class^="SectionBuilder_blockContainer__"] {
  padding-bottom: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  @media (--viewportMedium) {
    padding-left: 128px;
    padding-right: 128px;
    padding-top: 64px
  }
}

[id^="topbar-desktop-signup"] {
  background: rgba(98, 72, 255, 1);
  padding: 5%;
  padding-top: 2ch;
  padding-bottom: 2ch;
  width: 160px;
  text-align: center;
  border-radius: 7px;
  color: white;
  margin-top: 6% !important;
}

[id^="topbar-desktop-signup"]:hover {
  background-color: #3A2CA8; /* Darker shade for hover */
  color: white;
  cursor: pointer;
}

[id^="topbar-desktop-signup"] span {
  color: white;
}

[class^="CustomLinksMenu_createListingLinkOnly__"] {
  display: none !important;
}

[id^="help-wanted"] [class^="CustomAppearance_backgroundOverlay__"] {
  background: rgba(98, 72, 255, 0.6) !important;
}

[id^="introduction"] [class^="CustomAppearance_backgroundOverlay__"] {
  background: rgba(98, 72, 255, 0.6) !important;
}

[id^="introduction"] h1 {
  font-family: Aleo;
  font-size: 28px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
  max-width: 60ch !important;
  @media (--viewportMedium) {
    font-size: 40px;
  }
}

[id^="introduction"] p {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: white;
  margin-top: 16px !important;
  @media (--viewportMedium) {
    font-size: 28px;
  }
}

[id^="introduction"] a {
  color: #4A4A4A;
  text-align: center;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    width: 272px;
  }
}

[id^="find-a-coach-btn"] {
  background-color: white;
  width: 100%;
}

[id^="find-a-coach-btn"] [class^="SectionContainer_sectionContent__"] {
  background-color: white;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  margin-bottom: 20px;
  width: 100%;
}

[id^="find-a-coach-btn"] [class^="SectionBuilder_sectionDetails__"] {
  /* width: 100%; */
  padding: 0;
  margin: 0;
}

[id^="find-a-coach-btn"] [class^="Link_link__"] {
  color: #4A4A4A;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 83vw;
  @media (--viewportMedium) {
    width: 272px;
  }
}

[id^="parents-reviews"] [class^="SectionContainer_sectionContent__"] {
  background: #05EBC00F;
}

[id^="parents-reviews"] img {
  mix-blend-mode: darken;
}

[id^="reviews"] [class^="CustomAppearance_backgroundImageWrapper__"] {
  background-color: rgba(5, 235, 192, 0.06) !important;
}

[id^="coach-reviews"] [class^="CustomAppearance_backgroundImageWrapper__"] {
  background-color: rgba(255, 115, 72, 0.06) !important;
}

[id^="mobile-parent-review"] [class^="BlockDefault_media__"] {
  height: 25px;
  width: 133px;
}

[id^="mobile-parent-review"] [class^="BlockDefault_text__"] p:nth-of-type(1) {
  margin-top: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #4A4A4A;
}


[id^="mobile-parent-review"] [class^="BlockDefault_text__"] p:nth-of-type(2) {
  margin-top: 0;
}

[id^="leadership"] [class*="SectionBuilder_title__"] {
  font-family: Aleo;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
  text-align: left;
  max-width: 60ch !important;
  color: #19191a;
  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
  }

}

[class*="SectionBuilder_title__"] {
  font-family: Aleo;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  max-width: 60ch !important;
  color: #4A4A4A;
  @media (--viewportMedium) {
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
  }
}

[class*="SectionBuilder_description__"] {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #4A4A4A;
  margin-top: 4px !important;
  @media (--viewportMedium) {
    font-size: 18px;
    text-align: center;
  }
}

[class*="P_p__qMuDQ"] {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

}

[class*="Ingress_ingress__"] {
  font-size: 16px;
}

[id^="founders-div"] [class*="Heading_h3__"] {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
  text-align: left;
  color: #19191a;
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

[class*="Heading_h3__"] {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.63px;
  text-align: left;
  color: #4A4A4A;
  @media (--viewportMedium) {
    font-size: 22px;
  }
}

[id^="landing-page-faq-block-5"] [class^="P_p__"] {
  display: inline-block;
  line-height: 0px;
  max-width: 100ch;
  @media (--viewportMedium) {
    line-height: inherit;
  }
}

[id^="landing-page-faq-block-5"] a {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  display: inline-block;
  color: #4A4A4A;
  text-decoration: underline;
}

[id^="landing-page-faq-block-5"] a:hover {
  color: black;
  text-decoration: solid;
}

[id^="featured-sports-container"] {
  padding-top: 16px;
  padding-left: 16px;
  @media (--viewportMedium) {
    padding-left: auto;
  }
}

[class^="BookingTimeForm_checkBox__"] [class^="FieldCheckbox_text__"] {
  font-size: 13px;
}
